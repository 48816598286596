<template>
  <div class="create-account-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Account' }"
        >帳號管理</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'CreateAccount' }"
        >新增帳號</el-breadcrumb-item
      >
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="userForm"
        :model="user_form"
        :rules="user_rules"
        label-position="top"
      >
        <el-form-item label="信箱" prop="username">
          <el-input
            v-model="user_form.username"
            show-word-limit
            maxlength="128"
          ></el-input>
        </el-form-item>

        <el-form-item label="密碼" prop="password">
          <el-input
            v-model="user_form.password"
            type="password"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item label="確認密碼" prop="confirm">
          <el-input
            v-model="user_form.confirm"
            type="password"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item label="暱稱" prop="name">
          <el-input
            v-model="user_form.name"
            show-word-limit
            maxlength="64"
          ></el-input>
        </el-form-item>

        <el-form-item label="電話" prop="phone">
          <el-input
            v-model="user_form.phone"
            show-word-limit
            maxlength="64"
          ></el-input>
        </el-form-item>

        <el-form-item label="角色" prop="role">
          <el-select v-model="user_form.role">
            <el-option
              v-for="{ label, value } in roles"
              :key="value"
              :label="label"
              :value="value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="啟用狀態" prop="active">
          <el-radio-group v-model="user_form.active" size="small">
            <el-radio :label="true" :border="true">啟用</el-radio>
            <el-radio :label="false" :border="true">停用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { checkEmailExists, createUser, getRoles } from "@/api/user";

export default {
  name: "CreateAccount",
  data() {
    const validateUsername = async (rule, value, callback) => {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailRegex.test(value)) {
        callback(new Error("信箱不符合規定"));
      }

      if (await checkEmailExists({ email: value })) {
        callback(new Error("該信箱已被使用"));
      }

      callback();
    };

    const validatePassword = (rule, value, callback) => {
      if (value !== this.user_form.password) {
        callback("請確認密碼");
      }
      callback();
    };

    return {
      user_form: {
        username: "",
        password: "",
        confirm: "",
        name: "",
        role: "admin",
        phone: "",
        active: true,
      },
      user_rules: {
        username: [
          { required: true, message: "請輸入信箱", trigger: "blur" },
          { max: 128, message: "長度過長" },
          { validator: validateUsername, trigger: "blur" },
        ],
        password: [
          { required: true, message: "請輸入密碼", trigger: "blur" },
          { min: 8, max: 64, message: "長度請介於8-64之間" },
        ],
        confirm: [
          { required: true, message: "請確認密碼", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
        name: [
          { required: true, message: "請輸入暱稱", trigger: "blur" },
          { max: "64", message: "長度過長", trigger: "blur" },
        ],
        role: [
          { required: true, message: "請輸入角色", trigger: "change" },
          { max: 64, message: "角色長度過長", trigger: "change" },
        ],
        phone: [{ max: 64, message: "電話過長", trigger: "blur" }],
        active: [{ required: true, message: "請輸入狀態", trigger: "change" }],
      },
      roles: [],
    };
  },
  created() {
    this.handleGetRoles();
  },
  methods: {
    handleSubmit() {
      this.$refs.userForm.validate(async (valid) => {
        if (valid) {
          const cloneForm = { ...this.user_form };
          delete cloneForm.confirm;

          await createUser(cloneForm);

          this.$message({
            type: "success",
            message: "創建成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "Account" });
    },
    async handleGetRoles() {
      this.roles = await getRoles();
    },
  },
};
</script>

<style lang="scss" scoped></style>
